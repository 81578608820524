import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/qiio/qiio.png"
import qiio1 from "../../../assets/images/portfolio_items/qiio/qiio1.png"
import qiio2 from "../../../assets/images/portfolio_items/qiio/qiio2.png"
import qiio3 from "../../../assets/images/portfolio_items/qiio/qiio3.png"
import qiio4 from "../../../assets/images/portfolio_items/qiio/qiio4.png"
import qiio5 from "../../../assets/images/portfolio_items/qiio/qiio5.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | qiio - Drupal Development'}
    description="Drupal website development for an innovative Swiss IoT startup, quiio: discover more  on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/qiio'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="qiio, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, qiio"/>
            <div className="title"><h1>qiio<span></span></h1>
            <h2>Drupal website development for an innovative IoT startup</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Swiss IOT start-up qiio tasked ICON with articulating their unique, cellular based IoT for business concept. 
            ICON Designed and Built the website in Drupal.</p>
        <div className="portfolio-images">
            <img src={qiio1} alt="qiio, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, qiio"/>
            <img src={qiio4} alt="qiio, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, qiio"/>
            <img src={qiio2} alt="qiio, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, qiio"/>
            <img src={qiio3} alt="qiio, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, qiio"/>
            <img src={qiio5} alt="qiio, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, qiio"/>
        </div>
        <WorkFooter previous="credit-suisse" next="car4you"/>
        </div>
    </div>
</div>